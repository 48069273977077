import React from 'react';
import {NotFound as NotFoundUI} from 'benlux-ecommerce-ui';

import {MenuQuery} from '../queries/MenuQuery';

const NotFound = () =>
  <MenuQuery>
    {({menu}) => <NotFoundUI categories={menu}/>}
  </MenuQuery>

export default NotFound;
